@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.redirect-sm {
  @media (min-width: 768px) {
    width: 592px;
  }

  .marmita-modal__actions {
    justify-content: center;
  }

  .redirect-sm-modal {
    gap: 20px;
    display: flex;
    padding: 15px 25px;
    flex-flow: column;
    align-items: center;

    .title {
      gap: 20px;
      display: flex;
      flex-flow: column;
      align-items: center;

      @media (max-width: 768px) {
        flex-flow: column-reverse;
        margin-bottom: -10px;
      }

      h1 {
        margin: 0;
        font-weight: 600;

        @media (max-width: 768px) {
          font-size: 20px;
          line-height: 26px;
        }
      }

      .redirect-sm-image {
        width: fit-content;
      }
    }

    .subtitle {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0;
      text-align: center;
      color: #717171;

      @media (max-width: 768px) {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #3e3e3e;
      }
    }

    button {
      width: 240px;
    }
  }
}
